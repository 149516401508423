$collection-diff-nav-width: 320px;
$collection-diff-nav-bg-color: var(--content-color-constant);
$conflict-nav-color: var(--background-color-warning);
$source-color: var(--background-color-error);
$dest-color: var(--background-color-info);
$user-icon-width: 72px;

$breakpoint-xs: 480px !default;
$breakpoint-sm: 568px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 1024px !default;
$breakpoint-xl: 1280px !default;

.collection-diff {
  $pull-changes-btn-width: 160px;

  &__wrapper {
    padding: 0 var(--spacing-l);
    margin-top: var(--spacing-l);
    overflow-x: hidden;
  }

  &-header {
    &-label {
      padding: 0 var(--spacing-l);
      border-bottom: 1px solid var(--border-color-default);
    }

    &__text {
      margin-top: var(--spacing-l);
      display: inline-block;
      margin-left: var(--spacing-l);
      font-size: var(--text-size-l);
      font-weight: var(--text-weight-medium);
      color: var(--content-color-primary);
    }
  }

  &-alert {
    $close-btn-width: 64px;

    .pm-alert {
      padding: var(--spacing-s) var(--spacing-l);
    }

    .pm-alert-body {
      @media (min-width: $breakpoint-lg) {
        padding-right: $pull-changes-btn-width;
      }
    }

    &__actions {
      display: block;
      margin-top: var(--spacing-s);

      @media (min-width: $breakpoint-lg) {
        position: absolute;
        margin: 0;
        right: $close-btn-width + var(--spacing-s);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &-body {
    overflow-x: hidden;
    font-size: var(--text-size-m);

    &__title {
      color: var(--content-color-primary);
      text-transform: uppercase;
      margin-bottom: var(--spacing-s);

      .pm-badge-warning {
        display: inline;
        font-size: var(--text-size-s);
      }
    }

    &__request-label {
      margin-right: var(--spacing-s);
      display: inline-block;
      font-size: var(--text-size-m);
    }

    &__heading {
      color: var(--content-color-secondary);
      margin: 0;
      cursor: pointer;
      display: inline-block;

      .pm-icon {
        vertical-align: middle;
      }

      .pm-tooltip {
        text-transform: none;
      }

      .pm-badge > span {
        display: inline;
      }

      .pm-badge-success {
        background: transparent;
        color: var(--content-color-success);
        border: var(--border-width-default) solid var(--content-color-success);
      }

      .pm-badge-secondary {
        background: transparent;
        color: var(--content-color-secondary);
        border: var(--border-width-default) solid var(--border-color-default);
      }

      .pm-badge-error {
        background: transparent;
        color: var(--content-color-error);
        border: var(--border-width-default) solid var(--content-color-error);
      }
      .pm-badge-info {
        background: transparent;
        color: var(--base-color-info);
        border: var(--border-width-default) solid var(--base-color-info);
      }
    }

    &__nested-section {
      padding: var(--spacing-s) var(--spacing-xl);

      .pm-conflict-actions {
        right: var(--spacing-l);
      }

      h3 {
        margin-bottom: var(--spacing-xs);
      }

      .collection-diff-body__collapsible {
        margin: 0;
      }
    }

    &__collapsible {
      margin-bottom: var(--spacing-l);
      padding: var(--spacing-m);
      border: 1px solid var(--border-color-default);
      border-radius: var(--border-radius-default);

      &.collapsed {
        background: var(--background-color-secondary);
      }
    }

    &__section {
      padding: 0 var(--spacing-l) 0 var(--spacing-xl);
      margin: var(--spacing-l) 0;
    }

    &__toggle {
      cursor: pointer;
      color: var(--content-color-secondary);
      display: inline-block;
      font-weight: var(--text-weight-medium);
      margin-bottom: var(--spacing-l);
      margin-left: var(--spacing-s);

      &:before {
        font-size: var(--text-size-xxxl);
        width: var(--spacing-s);
        top: -2px; // non standard
        left: -12px; // non standard
        height: 0;
        content: ' ';
        position: relative;
        display: inline-block;
        border-color: transparent;
        border-left-color: inherit;
        border-width: 4px 0px 4px 5px;
        border-style: solid;
      }

      &.is-visible {
        &:before {
          border-color: transparent;
          border-top-color: inherit;
          border-width: 5px 4px 0;
        }
      }
    }

    &__nav-item {
      @media (min-width: $breakpoint-md) {
        margin-left: $collection-diff-nav-width;
      }
    }
  }

  &__element-title {
    margin-left: 8px;
  }

  &-nav {
    display: none;
    background: $collection-diff-nav-bg-color;
    position: sticky;
    top: 0;
    width: $collection-diff-nav-width;
    padding-top: var(--spacing-l);
    height: 100vh;
    overflow: auto;
    float: left;
    font-size: var(--text-size-m);
    border-right: var(--border-width-default) solid var(--border-color-default);

    @media (min-width: $breakpoint-md) {
      display: block;
    }

    &__prop {
      width: 100%;
      margin: 0;
      padding: var(--spacing-s);
      display: block;
      text-decoration: none;
      color: var(--content-color-primary);

      &--conflict {
        background: $conflict-nav-color;
      }

      &:hover {
        background: var(--background-color-secondary);
      }
    }

    &__type {
      display: inline-block;
      width: 5%;
      height: var(--spacing-l);
      border-radius: var(--border-radius-default);
      vertical-align: top;
      text-align: center;
      font-size: 10px;
      color: var(--content-color-secondary);
      border: var(--border-width-default) solid var(--content-color-secondary);

      &--added {
        color: var(--content-color-success);
        border: var(--border-width-default) solid var(--content-color-success);
      }

      &--modified {
        color: var(--content-color-secondary);
        border: var(--border-width-default) solid var(--content-color-secondary);
      }

      &--deleted {
        color: var(--content-color-error);
        border: var(--border-width-default) solid var(--content-color-error);
      }

      &--conflict {
        border: none;
        background: var(--content-color-warning);
        color: var(--content-color-constant);
      }
    }

    &__name {
      display: inline-block;
      text-transform: uppercase;
      width: 40%;
      white-space: nowrap;
      margin: auto var(--spacing-l);
      padding: 0;
      font-weight: var(--text-weight-medium);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__diff {
      padding: 0;
      width: 30%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__header {
      display: block;
      text-transform: uppercase;
      font-weight: var(--text-weight-medium);
      color: var(--content-color-secondary);
      padding: var(--spacing-s);
      text-decoration: none;
      margin-top: var(--spacing-l);

      &:hover {
        background: var(--background-color-secondary);
      }
    }

    &__item-name {
      display: inline-block;
      text-transform: uppercase;
      vertical-align: middle;
      white-space: nowrap;
      width: 80%;
      margin: auto var(--spacing-l);
      padding: 0;
      font-weight: var(--text-weight-medium);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-topbar {
    &__header {
      display: inline-block;
    }

    &__actions {
      float: right;
      margin-right: var(--spacing-l);
      margin-top: var(--spacing-s);

      &-cancel {
        margin-right: var(--spacing-s);
      }

      &-merge {
        margin-right: var(--spacing-s);
      }

      &-refresh {
        vertical-align: middle;
        background-color: transparent;

        &:hover {
          background-color: transparent;
        }

        &-icon {
          margin-right: var(--spacing-xs);
        }
      }
    }
  }

  &__source-destination-label {
    padding-bottom: var(--spacing-m);
    display: flex;
  }

  &__empty-state {
    position: relative;
    top: 30%;

    &-image {
      @include icon(
        '../assets/images/thumbnails/activity-feed.svg',
        160px,
        160px
      );
      margin: auto;
    }
  }

  &__error-state {
    margin-top: var(--spacing-xxl);
    position: relative;
    top: 30%;

    &-image {
      @include icon(
        '../assets/images/thumbnails/browse-view-offline.svg',
        160px,
        160px
      );
      margin: auto;
    }
  }

  &__infobar-icon {
    margin-right: var(--spacing-l);
  }

  &-offline {
    position: relative;
    top: 25%;

    &-image {
      @include icon(
        '../assets/images/thumbnails/browse-view-offline.svg',
        160px,
        160px
      );
      margin: auto;
    }
  }

  &__request {
    display: inline;

    &-method {
      display: inline;
      font-size: var(--text-size-l);
      font-weight: var(--text-weight-medium);
    }
  }

  &__request-method--small {
    display: inline;
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-regular);
  }
}

.collection-conflict {
  &-source {
    background: $source-color;
  }

  &-dest {
    background: $dest-color;
  }
}
