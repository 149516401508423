.infobar {
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 40px;
  height: auto;
  font-size: var(--text-size-m);
  color: var(--content-color-constant);

  a {
    color: var(--content-color-link);
    text-decoration: none;
  }
}

.infobar__msg_text {
  flex: 2;
  display: flex;
  margin-left: var(--spacing-l);
  align-items: center;
  text-align: left;

  .infobar__icon-wrapper {
    display: flex;
  }
  .infobar__icon {
    margin-right: var(--spacing-s);
  }
}

.infobar-info {
  background-color: var(--background-color-info);
  color: var(--content-color-primary);

  .infobar__icon {
    @include icon-color(var(--content-color-primary))
  }

  .infobar__dismiss_icon {
    @include icon-color(var(--content-color-primary));
  }
}

.infobar-warn {
  background-color: var(--background-color-warning);
  color: var(--content-color-primary);

  .infobar__icon {
    @include icon-color(var(--content-color-primary));
  }

  .infobar__dismiss_icon {
    @include icon-color(var(--content-color-primary));
  }
}

.infobar-error {
  background-color: var(--background-color-error);
  color: var(--content-color-primary);

  .infobar__icon {
    @include icon-color(var(--content-color-primary))
  }

  .infobar__dismiss_icon {
    @include icon-color(var(--content-color-primary));
  }
}

.infobar-success {
  background-color: var(--background-color-success);
  color: var(--content-color-primary);

  .infobar__icon {
    @include icon-color(var(--content-color-primary))
  }

  .infobar__dismiss_icon {
    @include icon-color(var(--content-color-primary));
  }
}

.infobar__msg_container {
  display: flex;
  flex: auto;
  margin-right: auto;
  justify-content: space-between;

  a {
    color: inherit;
    font-weight: var(--text-weight-medium);
  }

  .infobar-actions {
    align-self: center;
    margin-right: var(--spacing-l);

    .btn-small {
      padding: var(--spacing-zero) var(--spacing-l);
    }
    button{
      margin-right: var(--spacing-s);
    }
  }
}


.infobar__dismiss_container {
  margin-right: var(--spacing-l);
  cursor: pointer;

  .infobar__dismiss_icon {
    padding-top: var(--spacing-xs);
  }
}

.infobar_trial-warning-banner-icon, .infobar_plan-update-banner-icon {
  margin-right: var(--spacing-s);
}

.infobar_plan-update-banner {
  a {
    text-decoration: underline;
    font-weight: var(--text-weight-regular);
  }
}
