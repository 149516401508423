/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$request-editor-main-url-group-background-color: var(--background-color-tertiary);
$request-editor-main-url-group--hover-background-color: var(--highlight-background-color-secondary);
$request-editor-main-url-group--active-background-color: var(--background-color-primary);

.editor-tab-view-container {
  flex: 1;
  min-width: 0;
  min-height: 0;

  // this is needed to make request and response grid cells collapse to take
  // minimum space.
  display: flex;

  // Layout for tab view
  .request-editor-tab-view.editor-tab-view {
    width: 100%;
    display: flex;
    flex-direction: column;

    .editor-pane-response-viewer {
      .response-pane {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        min-height: 0;
      }
    }
  }
}


.layout-2-column {
  .request-editor {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 0;

    .requester-contents-group {
      flex: 1 auto;
      display: flex;
      flex-direction: column;
      border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      min-height: 0;
    }

    .requester-contents-group__section-content {
      flex: 1 auto;
      display: flex;
      flex-direction: column;
      min-height: 0;
    }

    .request-editor__tab-contents {
      flex: 1 auto;
      display: flex;
      flex-direction: column;
      min-height: 0;
    }
  }
}

.request-urlparams-editor-container,
.request-header-editor-container,
.request-body-formdata-editor-wrapper,
.request-body-formdata-editor-wrapper,
.request-body-urlencoded-editor-wrapper,
.request-configuration-list {
  overflow-y: auto;
}

.requester-tab-content-panes,
.requester-tab-content-pane,
.requester-tab-content-pane--request-editor,
.request-editor,
.requester-contents-group,
.requester-contents-group__section-content,
.request-editor__tab-contents,
.request-editor-tab-contents {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  min-height: 0;
}

.request-editor__main {
  padding: var(--spacing-s) var(--spacing-l);
  display: flex;
  flex-direction: row;

  .request-editor__main__url-group {
    flex: 1;
    min-width: 0;
  }

  .request-editor__main__button-group {
    flex: 0 0 100px;
  }
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 11;
}

.request-editor__main {
  position: relative;
  box-sizing: border-box;
  z-index: 7;
  background-color: var(--background-color-primary);
}

@keyframes animateBottomBorder {
  from {
    width: 50%;
  }

  to {
    width: 0%
  }
}

.request-editor__main__url-group {
  border-radius: var(--border-radius-default);
  background-color: $request-editor-main-url-group-background-color;
  margin-right: var(--spacing-s);
  max-height: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.request-editor__main__url-group-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.request-editor__main__url-gradient {
  width: 100%;
}

.request-editor__main__url-gradient-left {
  height: 2px;
  background: linear-gradient(270deg, rgb(255, 108, 55), rgba(255, 108, 55, 0.5), transparent 50%);
  animation: animateBottomBorder 0.5s ease-in;
  float: left;
  width: 50%;
}

.request-editor__main__url-gradient-right {
  height: 2px;
  background: linear-gradient(90deg, rgb(255, 108, 55), rgba(255, 108, 55, 0.5), transparent 50%);
  animation: animateBottomBorder 0.5s ease-in;
  float: right;
  width: 50%;
}

.request-method-editor {
  flex: 0 0 115px;
  color: var(--content-color-primary);
  background-color: $request-editor-main-url-group-background-color;
  border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
}

.request-urlpathvariables-editor-button {
  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-top-right-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);

  @include unselectable;
  @include clickable;

  color: var(--content-color-primary);
  font-size: var(--text-size-m);

  @include hover {
    background-color: $request-editor-main-url-group--hover-background-color;
  }

  @include active {
    background-color: $request-editor-main-url-group--active-background-color;
  }

  .layout-2-column & {
    flex: 0 0 60px;
  }
}

.request-editor__main__button-group {
  display: flex;
  flex-direction: row;
}

.request-prscript-editor,
.request-tests-editor,
.request-script-editor {
  position: relative;
  display: flex;
  flex: 1;

  // If the editor container is shrinked horizontally, the text-editor was not getting resized
  // accordingly in Firefox. This fixes the resizing issue.
  min-width: 0;
  min-height: 0;

  .editor {
    flex: 1;
  }

  .text-editor-wrapper {
    box-sizing: border-box;
  }

  &.comment-available__light {
    .view-lines {
      cursor: url('../assets/images/thumbnails/select-cursor-dark.svg') 4 8, text;

      .view-line {
        span {
          cursor: url('../assets/images/thumbnails/comment-select-cursor-dark.svg') 4 8, text;
        }
      }
    }
  }

  &.comment-available__dark {
    .view-lines {
      cursor: url('../assets/images/thumbnails/select-cursor-light.svg') 4 8, text;

      .view-line {
        span {
          cursor: url('../assets/images/thumbnails/comment-select-cursor-light.svg') 4 8, text;
        }
      }
    }
  }
}

.request-prscript-editor-snippets,
.request-tests-editor-snippets,
.request-script-snippets {
  flex: 0 0 auto;
  height: 100%;

  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-xs);

  &.show-ai-gen {
    position: relative;
    padding-top: 72px;
  }
}

.request-tests-editor-postbot-icon {
  position: absolute;
  top: 6px;
  right: 20px;

  button {
    background-color: var(--background-color-primary);
    border: 1px solid var(--content-color-secondary);

    svg {
      width: 12px;
      height: 12px;
      color: var(--content-color-secondary);
    }
  }
}

.request-tests-editor-postbot-icon:hover {
  button {
    svg {
      color: var(--content-color-tertiary);
    }
  }
}

.ai-ghost-text {
  color: var(--content-color-tertiary) !important;
}

.request-urlparams-editor-container,
.request-header-editor-container {
  .layout-2-column & {
    flex: 1;
    overflow-y: auto;
  }
}

.request-editor__tabs-wrapper {
  display: flex;
  height: var(--size-m);
  padding: var(--spacing-zero) var(--spacing-l);

  .request-editor__tabs {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .dropdown-label {
      font-weight: var(--text-weight-medium);
      white-space: pre;
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
    }

    .dropdown-caret.pm-icon {
      margin-left: var(--spacing-xs);
    }

    .dropdown-button>.btn {
      padding-left: 0px;
    }

    .entity-tabs-list {
      &--primary {
        gap: var(--spacing-xl);
      }

      &--primary-small {
        gap: var(--spacing-l);
      }

      &--primary,
      &--primary-small {

        .tab-primary,
        .tab-primary-small {
          .tab-text-wrapper {
            margin: unset;
          }
        }
      }
    }
  }
}

.request-editor-actions {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-left: var(--spacing-s);
  justify-content: flex-end;
  align-self: center;
  line-height: var(--line-height-m);

  &__item {
    padding: var(--spacing-zero) var(--spacing-xs);
    font-size: var(--text-size-m);
    color: var(--content-color-link);

    @include unselectable;
    @include clickable;
  }

  .dropdown-button {
    display: flex;
    align-items: center;
  }
}

.request-editor-actions-comments,
.request-editor-actions-reset {
  padding: var(--spacing-zero) var(--spacing-zero);
  font-size: var(--text-size-m);
  color: $button--text-color;

  @include unselectable;
  @include clickable;
}

.request-editor-actions-comments {
  position: relative; // add position relative to place the comment popover
  overflow: visible; // comments visbile
  display: flex;
  align-items: center;
  margin-right: var(--spacing-s);

  &>.comment-wrapper {
    cursor: pointer;

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.request-editor {
  position: relative;
}

// not needed after panes in examples
.request-editor-resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 10px;
  cursor: ew-resize;
}

.entity-empty {
  .entity-empty__subtitle--history-load {
    max-width: 300px !important;
  }
}

.request-editor-tab--auth,
.request-editor-tab--configurations,
.dropdown-menu-item--auth,
.dropdown-menu-item--configurations {
  &.is-disabled {
    cursor: not-allowed;
  }
}

.request-editor-tab--scripts {
  .tab-text-wrapper {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: calc(100% + var(--spacing-s));
      height: 95%;
      background-color: var(--background-color-tertiary);
      opacity: 0;
      border: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
      border-radius: var(--border-radius-default);
    }
  }

  &.is-highlighted {
    .tab-text-wrapper {
      &::before {
        opacity: 1;
      }

      >span {
        z-index: 1;
        color: var(--content-color-primary);
      }

      .request-editor-tabs-badge {
        z-index: 1;
      }

      .collection-modal-tabs-badge {
        z-index: 1;
      }
    }
  }
}
