.schema-editor-container {
  display: flex;
  flex: 1;
  flex-direction: column;

  .schema-editor-container__header {
    display: flex;
    flex: 0 0 37px;
    flex-direction:row;
    border-style: var(--border-style-solid);
    border-width: 0px 0px var(--border-width-default) 0px;
    border-color: var(--border-color-default);
    padding-left: 10px;

    .schema-editor-container__header-left {
      display: flex;
      flex: 1;
      align-items: center;

      .schema-editor-container__heading {
        font-family: $font-primary;
        color: var(--content-color-primary);
        font-size: 13px;
        font-weight: var(--text-weight-medium);
        margin-left: 5px;
      }

      .schema-editor-container__button {
        height: var(--size-s);
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-m);
        padding: var(--spacing-zero);
        min-width: 110px;
        color: var(--content-color-primary);
        @include icon-color(var(--content-color-primary));
      }
    }

    .schema-editor-container__header-right {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
    }
  }

  .schema-editor-container__content {
    display: flex;
    flex: 1;
    flex-direction: column;

    // done to prevent native scrollbar flicker while using draggable core
    overflow: hidden;

    &__glyph-warning {
      @include icon(
        '../assets/images/thumbnails/warning.svg',
        12px,
        12px
      );

      max-width: 12px;
      max-height: 12px;
      margin: 3px;
    }

    &__glyph-error {
      @include icon(
        '../assets/images/thumbnails/error.svg',
        12px,
        12px
      );

      max-width: 12px;
      max-height: 12px;
      margin: 3px;
    }
  }

  &__active-format {
    margin-left: var(--spacing-s);
    color: var(--content-color-primary);
    display: flex;
    align-items: center;
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-medium);
  }
}

.schema-editor-warning-modal-content {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  font-family: var(--text-family-default);
}

.unsaved-changes-warning-modal-content {
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
}

.unsaved-changes-warning-modal-footer {
  display: flex;
  flex-direction: row;
}

.schema-editor-warning-modal-footer {
  display: flex;
  flex-direction: row-reverse;
}
