.flowstudio {
  *,
  *::before,
  *::after
  & {
    box-sizing: border-box;
  }

  flex: 1;
}

@import './workbench/Components/index';
@import '~react-day-picker/dist/style.css';
