.monitor-tooltip-wrapper {
  display: inline-block;

  &.d-flex {
    display: flex;
  }

  &.d-block {
    display: block;
  }

  &.d-inline-block {
    display: inline-block;
  }
}

.monitor-request-access-tooltip {
  padding: var(--spacing-s);

  .msg {
    margin-bottom: var(--spacing-l);
  }
}
