$collection-modal-tab-text-font-size: $tab-text-font-size !default;
$collection-modal-tabs-meta-color: var(--background-color-secondary);

.collection-modal-tabs-count {
  padding: 0 5px;
  color: $collection-modal-tabs-meta-color;
}

.collection-modal-tabs-badge {
  display: inline-flex;
  width: 6px;
  height: 6px;
  margin-left: 5px;
  background-color: var(--base-color-success);
  border-radius: var(--border-radius-max);
}

.collection-modal-tabs-error-badge {
  display: inline-flex;
  width: 5px;
  height: 5px;
  transform: rotate(45deg);
  background-color: var(--base-color-error);
}

.collection-modal-tab-contents {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
}

.collection-modal-tab-content {
  height: 100%;
  flex: 1;
  display: flex;
  box-sizing: border-box;

  &-wrapper {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: var(--spacing-l) var(--spacing-zero) var(--spacing-zero) var(--spacing-l); //@todo[EntityInTabs]: Find a better fix for this
  }

  &.test-tab-deprecation-screen {
    flex: unset;
    height: unset;
    margin-top: var(--spacing-s);
  }

  .collection-modal-tab-content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding: var(--spacing-l) var(--spacing-zero) var(--spacing-zero) var(--spacing-l); //@todo[EntityInTabs]: Find a better fix for this
    box-sizing: border-box;

    &--no-padding {
      padding: 0;
    }

    .collection-modal-editor {
      flex: 1 auto;
      min-height: 0;
    }

    .prscript-editor-container,
    .tests-editor-container {
      margin-bottom: var(--spacing-l);
      min-width: 0;
    }
  }

  .collection-modal-tab-content-description {
    margin-top: var(--spacing-l);
    font-size: var(--text-size-m);
    font-family: $font-primary;
  }


  .collection-editor-content__variables-editor {
    margin-top: var(--spacing-l);
    .variable-editor-fork-banner {
      margin-top: var(--spacing-l);
    }
    .variables-editor__meta {
      &.is-bleed {
        padding-right: var(--spacing-m);
      }
    }
  }
}
