@import '../mixins/inline-variable';

.auto-suggest-group {
  display: flex;
  flex-direction: row;

  & > * {
    flex: 1;
  }
}

.read-only {
  // We need specific styled for read-only state hence adding important to override other styles
  cursor: not-allowed;
  color: var(--content-color-secondary);

  &:hover {
    opacity: 1 !important;
  }
}

.auto-suggest {
  position: relative;
  font-family: $font-primary;
  color: $app-generic-text-color;
  flex: 1;
  align-self: flex-start;

  &.is-focused {

    z-index: 2;

    .public-DraftStyleDefault-block {
      white-space: normal !important;
      text-overflow: unset;
    }

    .public-DraftEditor-content {
      display: flex;
      flex: 1 auto;
      width: 0;

      > div {
        flex: 1;
        overflow: hidden;
        > div {
          &:not(:first-child) {
            display: block;
          }
        }
      }
    }

    .auto-suggest-box {
      z-index: 10;
    }

    .auto-suggest-cell--multiline {
      .public-DraftEditor-content {
        div[data-block=true] {
          &:first-child {
            &::after {
              content: none;
            }


            .public-DraftStyleDefault-block {
              > span {
                br {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  &.is-overridden {
    .public-DraftStyleDefault-block {
      text-decoration: line-through;
      color: var(--content-color-tertiary);
    }
  }

  &:not(.is-focused) {
    .auto-suggest-cell--multiline {

      &.auto-suggest__collapsible {
        .public-DraftEditor-content {
          > div {
            display: flex;
            > div {
              display: block;
            }
          }
        }
      }

      &:not(.auto-suggest__collapsible) {
        .public-DraftEditor-content {
          div[data-block=true] {
            &:first-child {
              &::after {
                content: '...';
                opacity: 0.4;
              }
            }
          }
        }
      }

      div[data-block=true] {
        &:first-child {
          display: flex;
        }
      }
    }

    .auto-suggest__overflown {
      div[data-block=true] {
        &:first-child {
          display: flex;

          .public-DraftStyleDefault-block {
            margin-right: 2px;
            height: 18px;
          }

          &::after {
            content: '...';
            opacity: 0.4;
          }
        }
      }
    }

    .auto-suggest__multiline-overflown {
      .public-DraftEditor-content {
        &::after {
          content: '...';
          opacity: 0.4;
        }
      }
    }
  }


  .DraftEditor-root {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .auto-suggest-cell {
    padding: 0px 3px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }

  .auto-suggest-box {
    padding: 6px 10px;
    background-color: var(--background-color-primary);
    border-radius: var(--border-radius-default);
    border: 1px solid var(--input-border-color-default);

    &.read-only {
      background-color: var(--background-color-secondary);
    }
  }

  .auto-suggest__info_wrapper {
    display: flex;
    flex-direction: row;

    .auto-suggest__info_span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .auto-suggest__info_button {
      margin-left: var(--spacing-s);
      display: flex;
      align-items: center;
    }
  }

  .auto-suggest-preview-info__wrapper {
    display: flex;

    .auto-suggest-preview-info__span {
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: $font-primary;
    }

    .auto-suggest-preview-info_button {
      display: flex;
      margin-left: var(--spacing-s);
      align-items: center;
    }
  }

  .auto-suggest-cell--multiline {
    .public-DraftEditor-content {
      div[data-block=true] {
        &:first-child {
          .public-DraftStyleDefault-block {

            > span {
              br {
                display: none;
              }
            }
          }
        }

        &:not(:last-child) {
          .public-DraftStyleDefault-block {
            > span {
              br {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .auto-suggest__fixed-max-height {
    .public-DraftEditor-content {
      overflow: auto;
      max-height: 200px;
      align-items: flex-start;
    }
  }

  &.show-whitespaces {
    &.has-error {
      .auto-suggest-cell--multiline {
        .public-DraftEditor-content {
          div[data-block=true] {
            &:not(:last-child) {
              .public-DraftStyleDefault-block {
                > span {
                  br {
                    display: none;
                  }
                }

                &::after {
                  content: '↵';
                  font-size: 9px;
                  color: var(--content-color-tertiary);
                  padding: 3px 3px 1px;
                  background: var(--background-color-error);
                }
              }
            }
          }
        }
      }
    }

    &:not(.has-error) {
      .auto-suggest-cell--multiline {
        .public-DraftEditor-content {
          div[data-block=true] {
            &:not(:last-child) {
              .public-DraftStyleDefault-block {
                > span {
                  br {
                    display: none;
                  }
                }

                &::after {
                  content: '↵';
                  opacity: 0.4;
                  font-size: var(--spacing-s);
                  padding: 5px 3px;
                }
              }
            }
          }
        }
      }
    }
  }

  .public-DraftEditorPlaceholder-root {
    color: var(--content-color-tertiary);
    white-space: nowrap;
  }

  .DraftEditor-editorContainer {
    display: flex;
    flex: 1;
    min-width: 0;
  }

  .public-DraftEditor-content {
    display: flex;
    flex: 1;
    width: 0;
    align-items: center;
    > div {
      flex: 1;
      overflow: hidden;
      > div {
        &:not(:first-child) {
          display: none;
        }
      }
    }
  }

  .is-focused {
    .public-DraftStyleDefault-block {
        text-overflow: unset;
    }
  }

  .public-DraftStyleDefault-block {
    white-space: nowrap !important;
    overflow: hidden;
    &::-webkit-scrollbar {
      display:none;
    }
  }

 @include inlineVariables

}

.show-whitespaces {
  .wf__item {
    white-space: pre-wrap;

    .wf__tk {
      word-break: break-all;
      line-height: 14px;
    }

    .wf__ch {
      opacity: 0.6;
      margin: 1px;
    }

    .wf__qt {
      &::after {
        content: '"';
        opacity: 0.6;
      }
    }
  }

  .wf__sp {
    opacity: 1;
    font-family: monospace;
    position: relative;

    // To render dot for the whitespace character
    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 2px;
      background-color: var(--color-whitespace-char);
      border-radius: var(--border-radius-default);
      transform: translateX(-5px) translateY(7px);
    }
  }

  .wf__tb {
    font-size: var(--text-size-m);
    display: inline-block;

    &::before {
      content: '→';
      opacity: 0.4;
      font-size: 9px;
      position: absolute;
      padding: 2px 6px;
    }
  }
}

.auto-suggest__warning-btn,
.auto-suggest__error-btn {
  // We use absolute positioning and these "magic values" in order to properly position
  // the warning sign. The current tokens do not suffice due to very specific positioning needs
  position: absolute;
  top: 10px;
  right: 8px;
  background: unset;
  height: unset;
  width: unset;
  z-index: 1;
  cursor: default;

  &:hover, &:active {
    background: unset;
  }
}

.highlight-with-error {
  background: var(--background-color-error);
}

.highlight-with-warning {
  background: var(--background-color-warning);
}

.auto-suggest {
  &.has-error, &.has-warning {
    .auto-suggest__warning-btn,
    .auto-suggest__error-btn {
      // We require this override because, when the AutoSuggest has the warning
      // and error buttons inside, it shifts their position, compared to non-edit state.
      // This avoid the buttons not shifting in position visually.
      top: 6px;
    }

    .DraftEditor-editorContainer {
      .public-DraftEditor-content {
        margin-right: var(--spacing-l);
      }
    }
  }
}


.disable .auto-suggest .auto-suggest-box {
  background-color: var(--input-background-color-disabled);
}
