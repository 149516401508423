.agent-selection-details__popover {
  .tippy-content {
    width: 288px;
    padding: var(--spacing-xl);
    background-color: var(--background-color-primary);

    &:has(#desktop-callout) {
      width: 351px;
    }
  }
}

.agent-selection-details__confirm {
  margin-top: var(--spacing-m);
  height: var(--size-m);
  width: 100%;
}

.agent-selection-icon {
  display: flex;
  align-items: center;
  @include clickable;

  .agent-selection-label {
    font-size: var(--text-size-s);
    line-height: var(--line-height-s);
    margin-left: var(--spacing-xs);
  }

  @media only screen and (max-width: 768px) {
    .agent-selection-label {
      display: none;
    }
  }


  .sb__item__text {
    margin-bottom: 2px;
  }

  &-error {
    @include icon-color(var(--content-color-error) !important);
  }

  &-success {
    @include icon-color(var(--content-color-success) !important);
  }
}

.agent-selection-details__wrapper {
  display: flex;
  align-items: flex-start;
  height: 100%;
  border-radius: var(--border-radius-default);
  margin-top: 20px; // Using absolute value to achieve visual balance
  background: var(--background-color-primary);
  gap: var(--spacing-m);
  @include clickable;

  &:not(:first-child) {
    margin-top: var(--spacing-l);
  }

  &.active {
    border-color: var(--base-color-brand);
  }

  &.unsupported {
    cursor: not-allowed;
    pointer-events: none;
  }

  &.auto-select {
    cursor: default;
  }

  &.disabled {
    cursor: not-allowed;
  }

  .agent-selection-toggle_switch__wrapper {
    flex-shrink: 0;
  }
}

.agent-selection-details__divider {
  background-color: var(--border-color-default);
  height: var(--border-width-default);
  margin-top: var(--spacing-l);
}

.agent-selection-item {
  &__other_agents {
      cursor: pointer;
      display: flex;
      color: var(--content-color-secondary);
      margin-top: var(--spacing-l);
      margin-left: 0;
      gap: var(--spacing-s);

      &.disabled {
        cursor: not-allowed;
        color: var(--content-color-tertiary);
      }
    }

  &__radio {
    border: var(--border-width-default) var(--border-style-solid)
      var(--radio-border-color);
    border-radius: 50%;
    flex: 0 0 var(--spacing-l);
    height: var(--spacing-l);
    box-sizing: border-box;
    margin-top: 2px;
    @include clickable;

    @include hover {
      &:not(.active) {
        border-color: var(--radio-border-color);
      }
      @include hover {
        &.disabled {
          border-color: var(--radio-disabled-border-color);
        }
      }
    }

    &.active {
      border-width: var(--border-width-xl);
      border-color: var(--radio-checked-background-color);
    }

    &.unsupported {
      cursor: not-allowed;
      border-color: var(--radio-disabled-border-color);
    }

    &.disabled {
      cursor: not-allowed;
      border-color: var(--radio-disabled-border-color);
    }
  }

  &__label {
    display: flex;
    gap: 4px;
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);

    &.unsupported {
      color: var(--content-color-tertiary);

      > h5 {
        color: var(--content-color-tertiary);
      }
    }

    & .secondary {
      color: var(--content-color-secondary);
      font-weight: var(--text-weight-regular);
    }

    &.disabled {
      color: var(--content-color-tertiary);

      > h5 {
        color: var(--content-color-tertiary);
      }
    }
  }

  &__description {
    font-size: var(--text-size-s);
    line-height: var(--line-height-s);
    color: var(--content-color-secondary);

    & .details__link {
      text-decoration: underline;
      @include clickable;
    }

    &.unsupported {
      color: var(--content-color-error);
    }

    &.disabled {
      color: var(--content-color-tertiary);
    }
  }
}
