.editable-tag {
  height: 20px;
  width: 170px;
  border-radius: var(--border-radius-default);
  margin: 4px;
  padding: 4px;
  color: var(--content-color-primary);
  display: flex;
  align-items: center;

  .text:hover {
    cursor: text;
  }

  .pm-icon {
    margin-right: 8px;
  }

  .tag-remove-btn:hover {
    cursor: pointer;
  }

  &-regular {
    background-color: var(--background-color-secondary);
    border: solid 1px var(--border-color-default);
  }

  &-error {
    border: 1px dashed var(--base-color-error);
    background-color: var(--background-color-error);
    color: var(--content-color-error);
}

  &.is-selected {
    border: 1px solid var(--base-color-info);
  }
}
